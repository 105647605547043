<script lang="ts" setup>
import { useUserStore } from '~/store/users'
import { useAuthStore } from '~/modules/auth/stores/auth'

const usersStore = useUserStore()
const { initializeStore } = usersStore
const { currentJobContext } = storeToRefs(usersStore)
const jobContextFetchError = ref(false)
const app = useAppConfig()

const authStore = useAuthStore()
const authUser = computed(() => authStore.authUser)

onBeforeMount(async () => {
    try {
        await initializeStore()
    } catch (error) {
        console.error(error)
        jobContextFetchError.value = true
    }
})

if (app?.tairo?.collapse?.navigation?.items) {
    app.tairo.collapse.navigation.items = [
        {
            name: 'Dashboard',
            icon: { name: 'ph:kanban-fill', class: 'w-5 h-5' },
            to: '/',
        },
        {
            name: 'Inserate',
            icon: { name: 'ph:briefcase-fill', class: 'w-5 h-5' },
            activePath: '/inserate',
            children: [
                {
                    name: 'aktive Inserate',
                    icon: { name: 'ph:eye-duotone', class: 'w-5 h-5' },
                    to: '/inserate',
                },
                {
                    name: 'inaktive Inserate',
                    icon: { name: 'ph:eye-slash-duotone', class: 'w-5 h-5' },
                    to: '/inserate/deactivated',
                },
            ],
        },
    ]
}

const navigationItems = {
    templates: [
        {
            name: 'Vorlagen',
            icon: { name: 'ph:note-fill', class: 'w-5 h-5' },
            to: '/templates',
        },
    ],
    jobPresets: [
        {
            name: 'Jobvorlagen',
            icon: { name: 'ph:anchor-fill', class: 'w-5 h-5' },
            activePath: '/job-presets',
            children: [
                {
                    name: 'aktive Jobvorlagen',
                    icon: { name: 'ph:eye-duotone', class: 'w-5 h-5' },
                    to: '/job-presets',
                },
                {
                    name: 'inaktive Jobvorlagen',
                    icon: { name: 'ph:eye-slash-duotone', class: 'w-5 h-5' },
                    to: '/job-presets/deactivated',
                },
            ],

        },
    ],
    platforms: [
        {
            name: 'Plattformen',
            icon: { name: 'ph:devices-fill', class: 'w-5 h-5' },
            to: '/platforms',
        },
    ],
    users: [
        {
            name: 'Benutzer',
            icon: { name: 'ph:user-fill', class: 'w-5 h-5' },
            to: '/users',
        },
    ],
    contingent: [
        {
            name: 'Kontingent',
            icon: { name: 'ph:funnel-fill', class: 'w-5 h-5' },
            activePath: '/contingent',
            children: [
                {
                    name: 'Global',
                    icon: { name: 'ph:globe-simple-fill', class: 'w-4 h-4' },
                    to: '/contingent/global',
                },
                {
                    name: 'Jobcontext',
                    icon: { name: 'ph:flag-pennant-fill', class: 'w-4 h-4' },
                    to: '/contingent/jobcontext',
                },
            ],
        },
    ],
}

watch(authUser, () => {
    if (authUser.value?.meta?.authorisation?.includes('CAN_SET_ALL_TEMPLATES') && app?.tairo?.collapse?.navigation?.items) {
        const newItems = navigationItems.templates.filter((item) => !app.tairo.collapse.navigation.items.some((existingItem) => existingItem.to === item.to))
        app.tairo.collapse.navigation.items = [...app.tairo.collapse.navigation.items, ...newItems]
    }
    if (authUser.value?.meta?.authorisation?.includes('CAN_SET_ALL_JOBCONTEXTLIMITS') && authUser.value?.meta?.authorisation?.includes('CAN_SET_ALL_BRANCHLIMITS') && app?.tairo?.collapse?.navigation?.items) {
        const newItems = navigationItems.contingent.filter((item) => !app.tairo.collapse.navigation.items.some((existingItem) => existingItem.activePath === item.activePath))
        app.tairo.collapse.navigation.items = [...app.tairo.collapse.navigation.items, ...newItems]
    }
    if (authUser.value?.meta?.authorisation?.includes('CAN_SET_ALL_TEMPLATES') && app?.tairo?.collapse?.navigation?.items) {
        const newItems = navigationItems.platforms.filter((item) => !app.tairo.collapse.navigation.items.some((existingItem) => existingItem.to === item.to))
        app.tairo.collapse.navigation.items = [...app.tairo.collapse.navigation.items, ...newItems]
    }
    if (authUser.value?.meta?.authorisation?.includes('CAN_SET_ALL_TEMPLATES') && app?.tairo?.collapse?.navigation?.items) {
        const newItems = navigationItems.jobPresets.filter((item) => !app.tairo.collapse.navigation.items.some((existingItem) => existingItem.activePath === item.activePath))
        app.tairo.collapse.navigation.items = [...app.tairo.collapse.navigation.items, ...newItems]
    }
    if (authUser.value?.meta?.authorisation?.includes('CAN_SET_ALL_USERPASSWORDS') && app?.tairo?.collapse?.navigation?.items) {
        const newItems = navigationItems.users.filter((item) => !app.tairo.collapse.navigation.items.some((existingItem) => existingItem.to === item.to))
        app.tairo.collapse.navigation.items = [...app.tairo.collapse.navigation.items, ...newItems]
    }
})

onMounted(() => {
    const configRuntime = useRuntimeConfig()
    const { t } = useI18n()
    console.log(
        '🪃 %c Teampool ' + t('global.jobPortal') + ' %c v' + configRuntime.public.portalversion + ' ',
        'background-color: #FA4616; font-size: 12px; line-height: 20px; border-radius: 5px 0 0 5px; color: #1D1E20; font-weight: bold;',
        'background-color: rgb(250 70 22 / 0.25); font-size: 12px; line-height: 20px; border-radius: 0 5px 5px 0; color: white;',
    )
})
</script>

<template>
    <TairoCollapseLayout>
        <slot v-if="currentJobContext" />
        <BasePlaceholderPage v-else-if="jobContextFetchError" subtitle="Job-Portal derzeit nicht erreichbar."
                             title="Error">
            <template #image>
                <TeampoolLogo class="mx-auto" />
            </template>
        </BasePlaceholderPage>
    </TairoCollapseLayout>
</template>
<style>
[data-nui-tooltip]::after {
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.2);
}
</style>
